<template>
  <v-container>
    <v-card class="px-4 py-4 pb-4">
      <v-card-title class="d-flex align-center">
        <NavButton class="mb-2 mb-sm-0" />
        <h2 class="mb-0 ml-2" style="word-break: break-word">
          Procesos de fondos para catálogo electrónico
        </h2>
      </v-card-title>

      <DataTableComponent
        :headers="headers"
        :items="fondos.data"
        :show_loading="fondos.isLoading"
        :total_registros="total_registro"
        @paginar="manejarPaginacionRegistros"
      >
        <template #[`item.created_at`]="{ item }">
          {{ moment(item.created_at).format("DD/MM/YYYY") }}
        </template>
        <template #[`item.monto_programado`]="{ item }">
          {{
            Intl.NumberFormat("en-US", {
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 8,
            }).format(item.monto_programado)
          }}
        </template>
        <template #[`item.fondos_certificados`]="{ item }">
          {{
            Intl.NumberFormat("en-US", {
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 8,
            }).format(item.fondos_certificados)
          }}
        </template>
        <template #[`item.acciones`]="{ item }">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click="verDetalleProceso(item)"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>Ver detalle proceso</span>
          </v-tooltip>
        </template>
      </DataTableComponent>
    </v-card>
  </v-container>
</template>

<script>
import { NavButton } from "@/components/utils";
import DataTableComponent from "../../components/DataTableComponent.vue";
import {
  createPageable,
  setPageableResponse,
  togglePageable,
} from "@/utils/loadable";

export default {
  components: { NavButton, DataTableComponent },
  data: () => ({
    headers: [
      {
        text: "Código",
        value: "codigo_proceso",
        align: "center",
      },
      {
        text: "Proceso",
        value: "nombre_proceso",
        align: "center",
      },
      {
        text: "Fecha de creación",
        value: "created_at",
        align: "center",
      },
      {
        text: "Monto programado ($)",
        value: "monto_programado",
        align: "right",
      },
      {
        text: "Fondos certificado ($)",
        value: "fondos_certificados",
        align: "right",
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
      },
    ],

    fondos: createPageable([], 10),
    paginaActual: 1,
    registrosPorPagina: 10,
  }),
  computed: {
    total_registro() {
      return this.fondos.pagination.total_rows;
    },
  },
  methods: {
    manejarPaginacionRegistros(paginacion) {
      const { pagina, cantidad_por_pagina } = paginacion;
      this.paginaActual = pagina;
      this.registrosPorPagina = cantidad_por_pagina;
      this.listadoFondosCatalogo();
    },
    async listadoFondosCatalogo() {
      togglePageable(this.fondos);
      const filtros = {
        pagination: true,
        per_page: this.registrosPorPagina,
        page: this.paginaActual,
      };
      const { data, headers } =
        await this.services.CertificacionFondo.obtenerProcesosCatalogo(filtros);
      setPageableResponse(this.fondos, data, headers);
    },
    verDetalleProceso(item) {
      this.$router.push(`/certificar-fondos/${item.id}`);
    },
  },
  created() {
    this.listadoFondosCatalogo();
  },
};
</script>
